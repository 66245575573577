import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Bryllup - Oversigt</h1>
    <div>Vi skal giftes d. 6. juni 2020!</div>
    <ul>
      <li>
        <Link to="wishlist">Ønskeliste</Link>
      </li>
      <li>
        <Link to="ceremony">Ceremoni i haven (for de nærmeste)</Link>
      </li>
      <li>
        <Link to="party">Fest i Ladding forsamlingshus</Link>
      </li>
    </ul>
  </Layout>
);

export default IndexPage;
